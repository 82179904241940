import React from 'react';
import useAuth from './useAuth';
import { docRef } from "../firebase";
import { onSnapshot } from 'firebase/firestore';

import { IClientUser } from '../types';

const useAccount = () => {
    const { user } = useAuth()
    const [account, setAccount] = React.useState<IClientUser>()

    React.useEffect(() => {
        if (user) {
            const unsub = onSnapshot(
                docRef("users", user.uid),
                (doc: any) => {
                    setAccount(doc.data())
                }
            )

            return () => {
                unsub()
            }
        }
    }, [user])

    return account;
}

export default useAccount;