import styles from './Feature.module.scss'
import { Icon } from 'react-feather'

interface IProps {
    title: string
    desc: string
    icon: Icon
}

const Feature: React.FC<IProps> = (props) => {

    return <div className={styles.Container}>
        {<props.icon size={44} strokeWidth={2} color="#05c3dd" />}
        <h3>{props.title}</h3>
        <p>{props.desc}</p>
    </div>
}

export default Feature;