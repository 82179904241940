import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { PaymentRequestPaymentMethodEvent } from "@stripe/stripe-js"
import { api, auth } from "../firebase";

const useStripeSubmit = (
    amount: number,
    currency: string,
    intentID: string,
    setIntentID: (id: string) => void,
    anonymous: boolean,
    metadata: Record<string, any>,
) => {
    const navigate = useNavigate();
    const { username } = useParams();
    const stripe = useStripe();
    const elements = useElements();

    const uid = auth.currentUser?.uid

    const [loading, setLoading] = React.useState(true);
    const [errorMessage, setErrorMessage] = React.useState('');

    const [clientSecret, setClientSecret] = useState('');
    const [currencyCode, setCurrencyCode] = useState('');

    const successPath = "/payments/success?username=" + username

    useEffect(() => {
        const createIntent = async () => {
            setLoading(true);
            try {
                let intent;

                if (!intentID) {
                    const createIntent = api('payments-stripe-createIntent')

                    intent = await createIntent({ uid, username, amount, currency, anonymous })
                        .then((res: any) => res.data)
                        .catch(err => {
                            alert('An unexpected error has occured, Please refresh the page and try again')
                            console.log(err)
                            return
                        })

                    setIntentID(intent.id)
                } else {                    
                    const updateIntent = api('payments-stripe-updateIntent')

                    intent = await updateIntent({ uid, intentID, amount, anonymous })
                        .then((res: any) => res.data)
                        .catch(err => {
                            alert('An unexpected error has occured, Please refresh the page and try again')
                            console.log(err)
                            return
                        })
                }

                if (!intent) {
                    return
                }

                const {
                    client_secret,
                    success,
                    status,
                    currencyCode,
                } = intent;

                console.log(status)

                if (success) {
                    navigate(successPath);
                    return;
                }

                setCurrencyCode(currencyCode);
                setClientSecret(client_secret);

                if (status === 'authentication_required' || status === 'requires_confirmation' || status === 'requires_action') {
                    const result = await stripe!.confirmCardPayment(client_secret)

                    if (result.error) {
                        setErrorMessage(result.error.message!)
                    } else {
                        if (result.paymentIntent.status === 'succeeded') {
                            navigate(successPath)
                        }
                    }

                    return
                }
            } catch (err: any) {
                console.log(err);
                setErrorMessage(err.message);
            } finally {
                setLoading(false);
            }
        }

        if (stripe && amount > 1.5) {
            createIntent();
        }
    }, [stripe, amount]);

    const submitNewCard = async (e: React.FormEvent<HTMLFormElement>, name: any, email: any) => {
        e.preventDefault();
        try {
            setLoading(true);
            setErrorMessage('');

            if (!stripe || !elements) {
                return;
            }

            const cardElement = elements.getElement(CardElement);

            const result = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: cardElement!,
                    billing_details: {
                        name: name,
                        email: email
                    },
                    metadata: {
                        uid: uid || "",
                        ...metadata
                    }
                },
                receipt_email: email
            })

            if (result.error) {
                setErrorMessage(result.error.message!)
            } else {
                if (result.paymentIntent.status === 'succeeded') {
                    navigate(successPath)
                }
            }
        } catch (error: any) {
            setErrorMessage(error.message);
        } finally {
            setLoading(false);
        }
    }

    const submitPaymentRequest = async (e: PaymentRequestPaymentMethodEvent) => {
        const res = await stripe?.confirmCardPayment(clientSecret, {
            payment_method: e.paymentMethod.id,
        }, {
            handleActions: false,
        })

        if (res?.error) {
            e.complete('fail');
            setErrorMessage(res.error.message || "")
            return;
        }

        e.complete('success');

        if (res?.paymentIntent.status === 'succeeded') {
            navigate(successPath)
        }

        if (res?.paymentIntent.status === 'requires_action') {
            await stripe?.confirmCardPayment(clientSecret).then(res => {
                if (res.error) {
                    setErrorMessage(res.error.message || "");
                    return;
                }

                navigate(successPath)
            })
        }
    }

    return {
        loading: loading,
        errorMessage: errorMessage,
        currencyCode,
        submitNewCard,
        submitPaymentRequest
    };
}

export default useStripeSubmit;