import React from 'react';
import { useNavigate } from 'react-router-dom';
import { api } from '../../../firebase';
import useAccount from '../../../hooks/useAccount';

import BankDetailsInput from '../../../components/BankDetailsInput';
import ALink from '../../../components/ALink';
import Notification from '../../../components/Notification';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';

import MoneyTransferIcon from '../../../assets/img/money_transfer.png';

import styles from './Payouts.module.scss';

import { IPayment } from '../../../types';

const PayoutsScreen: React.FC = props => {
    const navigate = useNavigate()
    const account = useAccount()

    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState("")
    const [modalSentShown, setModalSentShown] = React.useState(false)

    const [bankDetailsSubmitted, setBankDetailsSubmitted] = React.useState(false)
    const [updateBankDetails, setUpdateBankDetails] = React.useState(false)

    const [total, setTotal] = React.useState(0)


    const unclaimedPayments = account?.unclaimedPayments || []

    React.useEffect(() => {
        if (unclaimedPayments) {
            calcTotal(unclaimedPayments)
        }
    }, [unclaimedPayments])

    const calcTotal = (payments: IPayment[]) => {
        let total = 0
        payments.forEach((payment) => {
            total += (payment.amount / 100)
        })

        setTotal(Math.round((total + Number.EPSILON) * 100) / 100)
    }

    const requestPayout = async () => {
        setLoading(true)
        setError("")

        const func = api("user-payouts-request");

        const res: any = await func()
            .then(res => res.data)
            .catch(err => {
                setError(err.message)
            })

        if (res?.success) {
            setTotal(0)
            setModalSentShown(true)
        }

        setLoading(false)
    }

    return <>
        {(account?.bankDetails && !account?.bankDetailsVerified)
            && <Notification>We are now verifying your bank details. This can take up to 24 hours</Notification>}
        <div className='col a' style={{ marginTop: 10 }}>
            <ALink
                onClick={() => {
                    navigate("/account")
                }}
            >
                BACK
            </ALink>

            {(!account?.bankDetails && !bankDetailsSubmitted) || updateBankDetails ?
                <BankDetailsInput
                    onSubmit={() => {
                        setBankDetailsSubmitted(true)
                        setUpdateBankDetails(false)
                    }}
                />
                :
                <div className='col' style={{ width: '90%' }}>
                    <h3 style={{ marginBottom: 0, fontWeight: 'bold' }}>Account Balance</h3>
                    <div className='row a'>
                        <h3 style={{ flex: 1, fontWeight: 'bold' }}>{total} {account?.currency?.toUpperCase()}</h3>

                        <Button
                            style={{ flex: 0 }}
                            loading={loading}
                            disabled={!account?.bankDetailsVerified || !total}
                            onClick={requestPayout}
                        >
                            PAYOUT
                        </Button>
                    </div>

                    {error && <div className={styles.error}>
                        {error}
                    </div>}

                    <div className='muted'>
                        {total ? (total + " " + account?.currency?.toUpperCase() + " available and ") : null}
                        {account?.pendingPayoutTotal ? Math.round((account.pendingPayoutTotal + Number.EPSILON) * 100) / 100 : 0}
                        {" "}{account?.currency?.toUpperCase()} pending
                    </div>

                    {account?.bankDetails && <div style={{ marginTop: 15 }}>
                        <h3 style={{ fontWeight: 'bold' }}>
                            Your Bank Details
                            <ALink
                                style={{ fontSize: 14 }}
                                onClick={() => setUpdateBankDetails(true)}
                            >
                                UPDATE
                            </ALink>
                        </h3>

                        <table className={styles.BankDetails}>
                            <tbody>
                                <tr>
                                    <th>Full Name</th>
                                    <td>{account.bankDetails.fullName}</td>
                                </tr>

                                <tr>
                                    <th>Bank Name</th>
                                    <td>{account.bankDetails.bankName}</td>
                                </tr>

                                <tr>
                                    <th>IBAN</th>
                                    <td>{account.bankDetails.iban}</td>
                                </tr>

                                <tr>
                                    <th>BIC</th>
                                    <td>{account.bankDetails.bic}</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>}
                </div>}

            <Modal
                active={modalSentShown}
                setActive={setModalSentShown}
            >
                <img
                    src={MoneyTransferIcon}
                    style={{
                        width: '35%',
                        marginTop: 35,
                        marginBottom: 15
                    }}
                />

                <h3 style={{ fontWeight: 'bold' }}>Request Received!</h3>

                <p style={{ textAlign: 'center', width: '90%' }}>
                    We have successfully received your payout request. We will be sending the money to your bank account
                    within 24 hours and send you a status update via email.
                </p>
            </Modal>
        </div>
    </>
}

export default PayoutsScreen;
