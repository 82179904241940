import styles from './Features.module.scss'

import { Shield, CreditCard, Send } from 'react-feather';
import Feature from './Feature';

const Features: React.FC = () => {

    return <section className={styles.Container}>
        <Feature
            title="Secure Payments"
            desc="Powered by Stripe, a leading payments gateway"
            icon={Shield}
        />


        <Feature
            title="Quick Payments"
            desc="With Apple Pay & Google Pay, gifting a waiter only takes 5 seconds!"
            icon={CreditCard}
        />

        <Feature
            title="Flexible Payouts"
            desc="We send you the funds to your bank account when you want"
            icon={Send}
        />
    </section>
}

export default Features;