import React, { useState } from 'react';
import { api } from '../../firebase';

import TextInput from "../TextInput";
import Loader from "../Loader";

import { X, Check } from "react-feather";

import styles from "./UsernameInput.module.scss";

const usernameRegex = /^[0-9a-zA-Z_]+$/

interface IProps {
    username?: string
    setUsername: (valid: boolean, value: string) => void
    disabled?: boolean
}

const UsernameInput: React.FC<IProps> = props => {

    const [username, setUsername] = useState(props.username || "")
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const [status, setStatus] = useState("")
    const [statusError, setStatusError] = useState(false)

    let typingTimeout = React.useRef<NodeJS.Timeout | null>(null)

    const onInputUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setError("")

        if (!usernameRegex.test(e.target.value) && e.target.value !== "") {
            return;
        }

        setUsername(e.target.value.toLowerCase())

        if ((!e.target.value && typingTimeout.current) || typingTimeout.current) {
            window.clearTimeout(typingTimeout.current)
        }

        if (!e.target.value) {
            setStatus("")
            setError("Field is empty")
            return;
        }

        typingTimeout.current = setTimeout(() => {
            checkUsername(e.target.value)
        }, 800)
    }

    const checkUsername = async (username: string) => {
        if (!username) {
            return
        }

        if (username === props.username) {
            setLoading(false)
            setStatus("")
            setError("")
            setStatusError(false)
            props.setUsername(true, props.username)
            return;
        }

        setLoading(true)
        setStatus("")

        const checkFunc = api("user-client-checkUsername")

        await checkFunc({ username: username }).then((res: any) => {
            if (res.data?.valid) {
                setStatusError(false)
                setStatus("Valid Username")
                props.setUsername(true, username)
            } else {
                setStatusError(true)
                setStatus("Username Taken")
                props.setUsername(false, username)
            }
            setLoading(false)
        })
    }

    return <div
        key="username_input_container"
        className={'col'}
        style={{ position: 'relative', flex: 1 }}
    >
        <TextInput
            key="username_input_field"
            value={username}
            disabled={props.disabled}
            placeholder="Username"
            onChange={onInputUsernameChange}
            onBlur={() => {
                checkUsername(username)
            }}
            containerStyle={{ marginBottom: 12 }}
            error={error}
        />

        <span
            className={`${styles.UsernameStatus} ${statusError ? styles.error : ''}`}
            style={(loading && !error) ? { marginBottom: -4 } : undefined} // add cool animation, don't know why
        >
            {(loading && !error) && <Loader style={{ color: '#05C3DD' }} />}
            {(status && !error) &&
                <>
                    {statusError ?
                        <X size={20} style={{ strokeWidth: 3 }} />
                        :
                        <Check size={20} style={{ strokeWidth: 3 }} />
                    }
                    {status}
                </>}
        </span>
    </div>
}

export default UsernameInput;