import styles from './Preview.module.scss'

import PhoneOutline from '../../../assets/img/iphone.png'
import Preview1Img from '../../../assets/img/screenshot.png'

const DemoQRImg = "https://www.qrcode-monkey.com/img/default-preview-qr.svg"

const Preview: React.FC = (props) => {

    return <section className={styles.Container}>
        <img src={DemoQRImg} className={styles.DemoQR} />
        <div className={styles.AnimatedCircle} />
        <img src={Preview1Img} className={styles.Image} />
        <img src={PhoneOutline} className={styles.Outline} />
    </section>
}

export default Preview