import React from 'react';
import './App.css';

import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from "react-cookie"
import Routes from './Routes';


function App() {
  return (
    <CookiesProvider>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </CookiesProvider>
  );
}

export default App;
