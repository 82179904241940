import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { signOut } from "@firebase/auth";
import { auth } from "../../firebase";

import { Menu, X } from 'react-feather';
import IconButton from "../../components/IconButton";
import Button from "../../components/Button";

import LogoFull from '../../assets/img/logo-full.png';

import styles from './AccountLayout.module.scss';

const AccountLayout: React.FC = props => {
    const navigate = useNavigate()

    const [topSheetShown, setTopSheetShown] = React.useState(false)

    return <div className={styles.Container}>
        <header>
            <div style={{ flex: 1 }}>
                <img src={LogoFull} className={styles.Logo} alt="Logo" />
            </div>

            <div style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton onClick={() => setTopSheetShown(true)}>
                    <Menu />
                </IconButton>
            </div>
        </header>

        <div className={`${styles.TopSheet} ${topSheetShown ? styles.active : ''}`}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ flex: 1 }}>
                    <img src={LogoFull} className={styles.Logo} alt="Logo" />
                </div>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton onClick={() => setTopSheetShown(false)}>
                        <X />
                    </IconButton>
                </div>
            </div>

            <div className={styles.SheetContainer}>
                <Button
                    c="#fff"
                    style={{
                        color: '#000',
                        marginTop: 10
                    }}
                    onClick={() => {
                        signOut(auth)
                        navigate("/login")
                        setTopSheetShown(false)
                    }}
                >
                    LOGOUT
                </Button>
            </div>
        </div>

        <main>
            <Outlet />
        </main>

        <footer>

        </footer>


        {/* <Modal
            active={modalAboutShown}
            setActive={setModalAboutShown}
            x
            style={{ paddingLeft: '5%', paddingRight: '5%', textAlign: 'center' }}
        >
            <h2 style={{ fontWeight: 'bold' }}>ABOUT</h2>

            <p style={{ marginTop: 0 }}>{aboutTextP1}</p>

            <p>{aboutTextP2}</p>

            <Button
                style={{ marginBottom: 20 }}
                onClick={() => navigate("/")}
            >
                LEARN MORE
            </Button>
        </Modal> */}
    </div>
}

export default AccountLayout;
