
import styles from './StepCard.module.scss'

interface IProps {
    step: number
    title: string
    desc: string | React.ReactNode
}

const StepCard: React.FC<IProps> = (props) => {

    const { step, title, desc } = props

    return <div className={styles.Container}>
        <div className={styles.StepCircle}>
            {step}
        </div>
        <h3>{title}</h3>
        <p>{desc}</p>
    </div>
}

export default StepCard
