import Header from '../../components/LandingPage/Header';
import Hero from '../../components/LandingPage/Hero';
import Preview from '../../components/LandingPage/Preview';
import About from '../../components/LandingPage/About';
import Steps from '../../components/LandingPage/Steps';
import ForWho from '../../components/LandingPage/ForWho';
import Features from '../../components/LandingPage/Features';
import Badges from '../../components/LandingPage/Badges';
import Pricing from '../../components/LandingPage/Pricing';
import Footer from '../../components/LandingPage/Footer';

import styles from './Home.module.scss';

function Home() {
  return (
    <div className={styles.wrapper}>
      <Header />

      <Hero />

      <Preview />

      <About />

      <Steps />

      <ForWho />

      <Features />

      <Badges />

      <Pricing />

      <Footer />
    </div>
  );
}

export default Home;
