import React, { useState } from "react";
import {useCookies} from "react-cookie";

import AuthForm from "../../../components/Forms/Auth";
import Button from "../../../components/Button";
import TextInput from "../../../components/TextInput";
import { useNavigate } from 'react-router-dom';

import { auth, api } from "../../../firebase";
import { createUserWithEmailAndPassword } from "@firebase/auth";

import styles from './Register.module.scss';

const RegisterScreen: React.FC = props => {
    const navigate = useNavigate();
    const [cookies] = useCookies()

    const [email, setEmail] = React.useState('');
    const [emailError, setEmailError] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [passwordError, setPasswordError] = React.useState('');
    const [password2, setPassword2] = React.useState('');
    const [passwordError2, setPassword2Error] = React.useState('');
    const [firstName, setFirstName] = React.useState('');
    const [firstNameError, setFirstNameError] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [lastNameError, setLastNameError] = React.useState('');
    const [address, setAddress] = React.useState('');
    const [addressError, setAddressError] = React.useState('');
    const [zipCode, setZipCode] = React.useState('');
    const [zipCodeError, setZipCodeError] = React.useState('');
    const [city, setCity] = React.useState('');
    const [cityError, setCityError] = React.useState('');
    const [country, setCountry] = React.useState('');
    const [countryError, setCountryError] = React.useState('');
    const [errorFirebase, setFirebaseError] = React.useState('');
    const [loading, setLoading] = useState(false);

    function checkEmail() {
        setEmailError('')
        let regEmail = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i).test(email)
        if (!regEmail) {
            setEmailError('Invalid email address');
            return false
        }
        return true
    }

    function checkPassword() {
        setPasswordError('')
        if (password === '') {
            setPasswordError('Field is empty')
            return false
        } else if (password.length < 6) {
            setPasswordError('Password must be more than 6 characters')
        } else if (password !== '' && password2 !== '' && password !== password2) {
            setPasswordError('Passwords don\'t match')
            return false;
        }

        if (passwordError2 === 'Passwords don\'t match') {
            setPassword2Error('')
        }
        return true
    }

    function checkPassword2() {
        setPassword2Error('')
        if (password2 === '') {
            setPassword2Error('Field is empty')
            return false
        } else if (password !== '' && password2 !== '' && password !== password2) {
            setPassword2Error('Passwords don\'t match');
            return false;
        }

        if (passwordError === 'Passwords don\'t match') {
            setPasswordError('')
        }
        return true
    }

    function checkFirstName() {
        setFirstNameError('')
        if (firstName === '') {
            setFirstNameError('Field is empty')
            return false
        }
        return true
    }

    function checkLastName() {
        setLastNameError('')
        if (lastName === '') {
            setLastNameError('Field is empty')
            return false
        }
        return true
    }

    function checkAddress() {
        setAddressError('')
        if (address === '') {
            setAddressError('Field is empty')
            return false
        }
        return true
    }

    function checkZipCode() {
        setZipCodeError('')
        if (zipCode === '') {
            setZipCodeError('Field is empty')
            return false
        }
        return true
    }

    function checkCity() {
        setCityError('')
        if (city === '') {
            setCityError('Field is empty')
            return false
        }
        return true
    }

    function checkCountry() {
        setCountryError('')
        if (country === '') {
            setCountryError('Field is empty')
            return false
        }
        return true
    }

    async function handleSubmit(e: React.SyntheticEvent) {
        e.preventDefault()
        try {
            setLoading(true);
            setFirebaseError("");

            let check = checkEmail();
            check = checkPassword() && check;
            check = checkPassword2() && check;
            check = checkFirstName() && check;
            check = checkLastName() && check;
            check = checkAddress() && check;
            check = checkZipCode() && check;
            check = checkCity() && check;
            check = checkCountry() && check;

            if (check) {
                await createUserWithEmailAndPassword(auth,
                    email,
                    password,
                ).then(async (credential) => {
                    const registerFunc = api("user-client-register");
                    const data = {
                        uid: credential.user.uid,
                        email: email,
                        firstname: firstName,
                        lastname: lastName,
                        address: {
                            line1: address,
                            zip: zipCode,
                            city: city,
                            country: country,
                        },
                        partnerID: cookies.partnerID,
                        partnerMetadata: cookies.metadata,
                    }
                    await registerFunc(data)
                });
                navigate('/account');
            }
        } catch (error: any) {
            if (error.code === "auth/email-already-in-use") {
                setFirebaseError("Email is already in use")
            } else if (error.code === "auth/user-disabled") {
                setFirebaseError("Could not register you. Please contact support for more help")
            } else if (error.code === "auth/weak-password") {
                setFirebaseError("Weak password. It should be at least 6 characters")
            } else {
                setFirebaseError(error.message)
            }
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className={styles.Container}>
            <AuthForm
                onSubmit={handleSubmit}
                title='Create an account'
                data-testid="register-form"
                // back={() => navigate("/login")}
            >
                <div className={styles.Inputs}>
                    <TextInput
                        name="email"
                        placeholder="Email"
                        type="email"
                        autoComplete="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        onBlur={checkEmail}
                        error={emailError}
                        data-testid="email"
                    />

                    <TextInput
                        name="password"
                        placeholder="Password"
                        type="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        onBlur={checkPassword}
                        error={passwordError}
                        data-testid="password"
                    />

                    <TextInput
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        type="password"
                        autoComplete="current-password"
                        value={password2}
                        onChange={(e) => setPassword2(e.target.value)}
                        onBlur={checkPassword2}
                        error={passwordError2}
                        data-testid="confirm-password"
                    />

                    <div style={{ display: 'flex', gap: 12 }}>
                        <TextInput
                            name="firstname"
                            placeholder="First Name"
                            autoComplete="given-name"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            onBlur={checkFirstName}
                            error={firstNameError}
                        />

                        <TextInput
                            name="lastname"
                            placeholder="Last Name"
                            autoComplete="family-name"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            onBlur={checkLastName}
                            error={lastNameError}
                        />
                    </div>
                    <div style={{ display: 'flex', gap: 12 }}>

                        <TextInput
                            name="address-line1"
                            placeholder="Address"
                            autoComplete="address-line1"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            onBlur={checkAddress}
                            error={addressError}
                            containerStyle={{ flex: 2 }}
                        />

                        <TextInput
                            name="postal-code"
                            placeholder="ZIP code"
                            autoComplete="postal-code"
                            value={zipCode}
                            onChange={(e) => setZipCode(e.target.value)}
                            onBlur={checkZipCode}
                            error={zipCodeError}
                            containerStyle={{ flex: 1 }}
                        />
                    </div>

                    <div style={{ display: 'flex', gap: 12 }}>
                        <TextInput
                            name="address-level2"
                            placeholder="City"
                            autoComplete="address-level2"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                            onBlur={checkCity}
                            error={cityError}
                        />

                        <TextInput
                            name="country"
                            placeholder="Country"
                            autoComplete="country-name"
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                            onBlur={checkCountry}
                            error={countryError}
                        />
                    </div>
                </div>

                <div>
                    <Button name="btnSubmit" type="submit" loading={loading}>
                        Register
                    </Button>
                </div>

                {errorFirebase !== "" && <p className={styles.errorText}>{errorFirebase}</p>}
            </AuthForm>
        </div>
    );
};

export default RegisterScreen;