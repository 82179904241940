import { initializeApp } from "firebase/app";
import {
  getAnalytics,
  logEvent as logAnalyticsEvent,
  isSupported as isAnalyticsSupported
} from "firebase/analytics";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAuth } from "firebase/auth";
import {
  getFirestore,
  doc,
  collection,
  getDoc as getFirestoreDoc,
  setDoc as setFirestoreDoc,
  addDoc as addFirestoreDoc,
  updateDoc as updateFirestoreDoc,
  query as firestoreQuery,
  where, WhereFilterOp,
  getDocs
} from "firebase/firestore"
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDixPmLrMEvudzLu0Zuqt8DtlWsKZ7xayA",
  authDomain: "giftyrates.firebaseapp.com",
  projectId: "giftyrates",
  storageBucket: "giftyrates.appspot.com",
  messagingSenderId: "281494124744",
  appId: "1:281494124744:web:f452696766018277f38dfd",
  measurementId: "G-30QMHWCSVQ"
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

const analytics = isAnalyticsSupported().then((res) => res && getAnalytics(app));

const functions = getFunctions(app);
const api = (name: string) => httpsCallable(functions, name);

const firestore = getFirestore(app);

const storage = getStorage(app);

const docRef = (path: string, id: string) => {
  return doc(firestore, path, id)
}

const collectionRef = (path: string) => {
  return collection(firestore, path)
}

const getDoc = async (path: string, id: string) => {
  const ref = docRef(path, id);
  const doc = await getFirestoreDoc(ref);
  return doc.data();
}

const setDoc = async (path: string, id: string, data: any) => {
  const ref = docRef(path, id);
  return setFirestoreDoc(ref, data)
}

const addDoc = async (path: string, data: any) => {
  const ref = collectionRef(path)
  return addFirestoreDoc(ref, data)
}

const updateDoc = async (path: string, id: string, data: any) => {
  const ref = docRef(path, id);
  return updateFirestoreDoc(ref, data)
}

const query = async (path: string, field: string, filterOp: WhereFilterOp, value: any) => {
  const ref = collectionRef(path)

  const q = firestoreQuery(ref, where(field, filterOp, value))

  const querySnapshot = await getDocs(q)

  return querySnapshot
}

export {
  auth,
  api,
  analytics,
  firestore,
  docRef,
  collectionRef,
  getDoc,
  setDoc,
  addDoc,
  updateDoc,
  query,
  storage
};
