import React, { useState } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import useAuth from "../../hooks/useAuth";
import useAccount from '../../hooks/useAccount';
import { getDoc } from '../../firebase';

import Loader from '../../components/Loader';

import CreateAccount from './Create';

import Notification from '../../components/Notification';

import styles from './Account.module.scss';

import { IClientFull } from "../../types/index";

function Account() {
  const navigate = useNavigate()
  const location = useLocation()

  const { user } = useAuth()
  const account = useAccount()

  const [loading, setLoading] = useState(true)
  const [client, setClient] = useState<IClientFull | undefined>()

  React.useEffect(() => {
    if (user) {
      fetch()
    }
  }, [user])

  async function fetch() {
    const client = await getDoc('clients', user!.uid) as IClientFull

    setClient(client)

    setLoading(false)
  }

  if (loading) {
    return <div className={styles.Container}>
      <Loader
        style={{
          color: '#05C3DD',
          width: 40,
          height: 40,
          alignSelf: 'center'
        }}
      />
    </div>
  }

  if (!client) {
    return <CreateAccount setClient={(client: IClientFull) => setClient(client)} />
  }

  return <>
    {!client.profiles?.length && location.pathname !== "/account/edit" ?
      <Notification
        onClick={() => {
          navigate("/account/edit")
        }}
      >
        No profiles have been added yet. Click here to add profiles and start accepting tips!
      </Notification>
      : null}

    {!location.pathname.includes("payouts") ?
      !account?.bankDetails &&
      <Notification
        onClick={() => {
          navigate("payouts")
        }}
      >
        Please input your bank details to accept payouts.
      </Notification>
      : null}
    <Outlet context={[client, setClient]} />
  </>
}

export default Account;