import React from 'react';
import { auth } from '../firebase';
import { User } from 'firebase/auth'

const useAuth = () => {
    const [user, setUser] = React.useState<User | null>(auth.currentUser)
    const [loading, setLoading] = React.useState(true)

    React.useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setUser(user)
            if (loading) {
                setLoading(false)
            }
        })

        return () => {
            unsubscribe()
        }
    })

    if (user) {
        return { user, loading: false }
    }

    return { user, loading };
}

export default useAuth;