import { Phone, Edit, Camera, Trash } from "react-feather";
import styles from "./EditProfileCard.module.scss";
import { IProfile } from "../../types";
import IconButton from "../IconButton";

interface IProps {
    profile: IProfile
    onClick: (profile: IProfile) => void
    onDelete: () => void
}

const EditProfileCard: React.FC<IProps> = props => {
    const { profile } = props

    return <div
        className={`row a ${styles.Container}`}
    >
        {profile.photoURL ?
            <img
                src={profile.photoURL}
                className={styles.Img}
            />
            :
            <div className={styles.ImgPlaceholder}>
                <Camera color="white" />
            </div>
        }
        <div
            className="col"
            style={{
                paddingLeft: 10,
                flex: 1,
                justifyContent: "center"
            }}
        >
            <h4 style={{ marginTop: 0, marginBottom: 4 }}>{profile.displayName?.toUpperCase()}</h4>
            {profile.phoneNumber ?
                <div className="row a">
                    <Phone size={16} style={{ marginRight: 2 }} />
                    {profile.phoneNumber}
                </div>
                : null}
        </div>
        <div
            style={{
                paddingRight: 10
            }}
            className="row a"
        >
            <IconButton
                onClick={() => {
                    props.onClick(profile)
                }}
            >
                <Edit />
            </IconButton>
            
            <IconButton
                onClick={() => {
                    props.onDelete()
                }}
            >
                <Trash color="red" />
            </IconButton>
        </div>
    </div>
}

export default EditProfileCard;
