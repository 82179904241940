import { useEffect, useState, useRef } from "react";
import { recommendedTips } from "../../../constants/tips";

import Button from "../../../components/Button";
import { Edit3 } from "react-feather";

import styles from "../Tip.module.scss";

import { IClient } from "../index";

interface IProps {
    amount: number | ''
    setAmount: (amount: number | '') => void
    selectedTipIndex: number
    setSelectedTipIndex: React.Dispatch<React.SetStateAction<number>>
    client: IClient
    anonymous: boolean
    setAnonymous: (bool: boolean) => void
    onNext: () => void
}

const SelectTip: React.FC<IProps> = props => {
    const {
        amount,
        setAmount,
        selectedTipIndex,
        setSelectedTipIndex,
        client,
        anonymous,
        setAnonymous,
        onNext
    } = props

    const [inputCustom, setInputCustom] = useState(false)
    const customInputRef = useRef<HTMLInputElement>(null)
    const [customInputError, setCustomInputError] = useState('')

    useEffect(() => {
        if (inputCustom) {
            customInputRef.current?.focus()
        }
    }, [inputCustom])


    useEffect(() => {
        if (amount < 1.5 && amount > 0) {
            setCustomInputError(`Sorry, the minimum amount is ${client?.currency}1.5`)
        } else if (customInputError) {
            setCustomInputError('')
        }
    }, [amount])

    return <>
        <div className={'row a ' + styles.TipCircles}>
            {recommendedTips.map((el, index) => <div
                key={'circle' + index}
                className={`${styles.TipCircle} ${selectedTipIndex === index ? styles.selected : ''}`}
                onClick={() => {
                    setAmount(el.amount)
                    setSelectedTipIndex(index)
                    if (inputCustom) {
                        setInputCustom(false)
                    }
                }}
            >
                <div style={{
                    fontSize: 32
                }}>
                    {el.icon}
                </div>

                <div style={{
                    fontSize: 14,
                }}>
                    {client?.currency}{el.amount}
                </div>
            </div>)}

            <div
                className={`
                ${styles.TipCircle}
                ${inputCustom ? styles.selected : ''}
                ${styles.CustomTipCircle} ${customInputError ? styles.error : ''}
            `}
                style={{
                    color: 'gray',
                    marginRight: inputCustom ? 75 : undefined
                }}
                onClick={() => {
                    if (!inputCustom) {
                        setInputCustom(true)
                        setAmount('')
                        setSelectedTipIndex(recommendedTips.length)
                    }
                }}
            >
                {!inputCustom ? <Edit3 size={24} /> :
                    <input
                        ref={customInputRef}
                        className={styles.CustomInput}
                        type='number'
                        step='any'
                        autoFocus={true}
                        onBlur={(e) => {
                            if (!amount) {
                                setInputCustom(false)
                                setAmount(2)
                            }

                            const text = e.target.value;

                            const reg = /^\d*\.?\d*$/

                            if (reg.test(text)) {
                                const num = Number(text)
                                setAmount(Math.floor(num * 100) / 100)
                            }
                        }}
                        onChange={(e) => {
                            const text = e.target.value;

                            if (!text.includes('.') && text.length > 6) {
                                return
                            }

                            if (text === '') {
                                setAmount('')
                                return
                            }
                        }}
                    />}
            </div>
        </div>

        {customInputError && <span className={styles.CustomInputError}>{customInputError || '\u00A0'}</span>}

        <h1
            style={{
                marginTop: 0,
                alignSelf: 'center'
            }}
        >
            {!inputCustom ? recommendedTips[selectedTipIndex]?.icon : null}{" "}
            {client.currency}{amount} gift
        </h1>

        <label
            htmlFor="anonymous"
            className={styles.LabelAnonymous}
        >
            <input
                id="anonymous"
                name="anonymous"
                type="checkbox"
                defaultChecked={anonymous}
                onChange={(e) => {
                    setAnonymous(e.target.checked)
                }}
            />
            &nbsp;Anonymous tip
        </label>

        <p
            style={{
                textAlign: "center",
            }}
        >
            When you gift, they will be notified by text message of your gift
        </p>

        <Button
            style={{ margin: '10px 15px 20px 15px', width: 'auto' }}
            disabled={!!customInputError || !amount}
            onClick={onNext}
        >
            NEXT
        </Button>
    </>
}

export default SelectTip;
