import React from 'react'
import { useNavigate } from 'react-router-dom';

import ALink from '../../ALink';

import Logo from '../../../assets/img/logo-full.png';

import styles from './Header.module.scss'

const Header: React.FC = (props) => {
    const navigate = useNavigate();

    return <header>
        <img src={Logo} className={styles.Logo} />

        <div style={{ flex: 1 }}>

        </div>

        <nav className={styles.AuthContainer}>
            <ALink
                onClick={() => {
                    navigate('login')
                }}
            >
                Log In
            </ALink>
        </nav>
    </header>
}

export default Header;