import { Star } from "react-feather";
import IconButton from "../IconButton";

interface IProps {
    value: number
    setStars: (value: number) => void
}

const SelectStars: React.FC<IProps> = props => {
    return <div
        className="row a"
        style={{
            justifyContent: "center"
        }}
    >
        {[...Array(5)].map((_, index) =>
            <IconButton
                key={"star" + index}
                onClick={() => {
                    props.setStars(index + 1)
                }}
            >
                <Star
                    color="#FDCC0D"
                    size={32}
                    fill={props.value > index ? "#FDCC0D" : "#fff"}
                    style={{
                        transition: "0.3s all"
                    }}
                />
            </IconButton>
        )}
    </div>
}

export default SelectStars;
