import React from 'react';

import styles from './Banner.module.scss';

const Banner:React.FC<React.HTMLAttributes<HTMLDivElement>> = props => {
    return <div className={styles.Container} {...props}>
        {props.children}
    </div>
}

export default Banner