import React from "react";
import { Routes as Switch, Route, useSearchParams, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import Home from "./Home";
import UserLayout from "../Layout/UserLayout";
import TipScreen from "./Tip";
import PaymentSuccessScreen from "./Tip/PaymentSuccess";

import LoginScreen from "./auth/Login";
import RegisterScreen from "./auth/Register";
import ForgotPasswordScreen from "./auth/Forgot";

import Account from "./Account";
import AccountTipsContainer from "./Account/Tips";
import EditAccount from "./Account/Edit";
import PayoutsAccount from "./Account/Payouts";

import PrivateOutlet from "../components/PrivateOutlet";

const Routes: React.FC = () => {
    const location = useLocation()
    const [searchParams] = useSearchParams()

    const [, setCookie] = useCookies();

    React.useEffect(() => {
        const partner = searchParams.get("partner")
        const metadata = searchParams.get("metadata")

        if (partner) {
            setCookie("partnerID", partner)
            setCookie("metadata", metadata)
        }
    }, [location])

    return <Switch>
        <Route index element={<Home />} />

        <Route path="login" element={<LoginScreen />} />
        <Route path="register" element={<RegisterScreen />} />
        <Route path="forgot" element={<ForgotPasswordScreen />} />

        <Route path="account" element={<PrivateOutlet />}>
            <Route element={<Account />}>
                <Route index element={<AccountTipsContainer />} />
                <Route path="edit" element={<EditAccount />} />

                <Route path="payouts">
                    <Route index element={<PayoutsAccount />} />
                </Route>
            </Route>
        </Route>

        <Route path=":username" element={<UserLayout />}>
            <Route index element={<TipScreen />} />
            <Route path="success" element={<PaymentSuccessScreen />} />
        </Route>
    </Switch>;
}

export default Routes;