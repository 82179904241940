import React from 'react';
import { useOutletContext, useNavigate } from 'react-router-dom';
import { api } from '../../../firebase';

import ALink from '../../../components/ALink';
import IconButton from '../../../components/IconButton';

import { Copy, Download } from 'react-feather';

import styles from './AccountTips.module.scss';

import { IClientFull } from '../../../types';
import Loader from '../../../components/Loader';
import TipsHistory from '../../../components/TipsHistory';

const AccountTips: React.FC = props => {
    const navigate = useNavigate()

    const [client, setClient] = useOutletContext<[
        IClientFull | undefined,
        React.Dispatch<React.SetStateAction<IClientFull>>
    ]>()

    const [QRCode, setQRCode] = React.useState<string>()

    const link = 'https://giftyrates.com/' + client?.username;

    React.useEffect(() => {
        if (client) {
            getQRCode()
        }
    }, [client])

    async function getQRCode() {
        const func = api("user-client-createQRCode")

        const res = await func({ data: link })

        const url = res.data as string;

        setQRCode(url)
    }

    function downloadQRCode() {
        if (QRCode) {
            var a = document.createElement("a");
            a.href = QRCode;
            a.download = `GIFTYRATES-QR-CODE-${client!.username}.png`;
            a.click();
        }
    }

    return <div className={'col a'} style={{ marginTop: 10 }}>
        <div className='row a'>
            <ALink
                onClick={() => {
                    navigate("edit")
                }}
            >
                EDIT PROFILES
            </ALink>

            <ALink
                onClick={() => {
                    navigate("payouts")
                }}
            >
                PAYOUTS
            </ALink>
        </div>

        <h4>People scan this to tip you</h4>

        {QRCode ? <>
            <img src={QRCode} className={styles.QRCode} />

            <IconButton
                style={{
                    backgroundColor: '#05c3dd',
                    color: 'white'
                }}
                onClick={downloadQRCode}
            >
                <Download />
            </IconButton>
        </> :
            <Loader
                style={{
                    color: '#05c3dd',
                    marginTop: 30,
                    marginBottom: 30
                }}
            />
        }

        <p style={{ marginBottom: 0 }}>
            Your unique link:<br />
        </p>

        <div className='row a' style={{ marginBottom: 10 }}>
            <ALink
                style={{
                    alignSelf: 'center',
                    marginTop: 10,
                    marginBottom: 10
                }}
                href={link}
                rel="noreferrer"
                target="_blank"
            >
                {link}
            </ALink>
            <IconButton
                style={{ color: 'gray' }}
                onClick={() => {
                    navigator.clipboard.writeText(link)
                }}>
                <Copy />
                <span style={{ fontSize: '0.8em' }}>COPY</span>
            </IconButton>
        </div>

        <TipsHistory />
    </div>
}

export default AccountTips;
