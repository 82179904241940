import { useNavigate } from 'react-router-dom';

import styles from './Pricing.module.scss';

import { Plus, Check, Star, ArrowRight } from 'react-feather';

const Pricing: React.FC = () => {
    const navigate = useNavigate();

    return <section className={styles.Container}>
        <h2 style={{ fontWeight: 'bold' }}>Simple Pricing</h2>

        <div className={styles.Card}>
            <div className={styles.Head}>
                <p style={{ color: "#05c3dd" }}>ONE PLAN</p>
            </div>
            <div className={styles.Main}>
                <div
                    className={styles.desc + ' muted'}
                >
                    Access a complete gifting platform with simple, pay-as-you-go pricing
                </div>
                <h2
                    style={{
                        fontSize: '2rem',
                        lineHeight: '60px',
                        marginTop: 20,
                        marginBottom: 0,
                    }}
                >
                    2.5%
                    {/* <Plus
                        size={18}
                        strokeWidth={2}
                        color='gray'
                        style={{
                            marginLeft: 5,
                            marginRight: 5
                        }}
                    />
                    <span style={{ fontSize: '1rem', verticalAlign: 'middle' }}>0.25€</span> */}
                </h2>
                <span className='muted'>per gift*</span>

                <ul>
                    <li>
                        <div className={styles.icon}>
                            <Check size={18} />
                        </div>
                        Everything you need to start accepting digital gifts
                    </li>

                    <li>
                        <div className={styles.icon}>
                            <Star size={18} />
                        </div>
                        No setup, monthly fees or hidden fees
                    </li>
                </ul>

                <div
                    className={"muted"}
                    style={{
                        marginBottom: 12
                    }}
                >
                    *plus card processing fees
                </div>
            </div>
            <div
                className={styles.Footer}
                onClick={() => navigate("/register")}
            >
                GET STARTED IN MINUTES
                <ArrowRight size={18} />
            </div>
        </div>
    </section>
}

export default Pricing;