function ISOtoCurrencySymbol(symbol: string) {
    switch (symbol) {
        case "usd":
            return "$";
        case "eur":
            return "€";
        case "zar":
            return "R";
        default:
            return "usd";
    }
};

export {
    ISOtoCurrencySymbol,
}
