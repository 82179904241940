
import styles from './Steps.module.scss'

import StepCard from './StepCard'

const Steps: React.FC = (props) => {

    return <section className={styles.Container}>
        <h2 style={{ fontWeight: 'bold' }}>How does it work?</h2>

        <div className={styles.Grid}>
            <StepCard
                step={1}
                title='Integration'
                desc={
                    <>
                        Integrate GiftyRates into your emenu platform using our <a href="https://giftyrates.com/partners.html">API</a>
                    </>
                }
            />

            <StepCard
                step={2}
                title='Spread the word'
                desc='Notify your costumers so that they can activate their giftyrate account through a link in you fashboard'
            />

            <StepCard
                step={3}
                title='Increase Revenue'
                desc='Receive 40% commission  on every gift made through our platform'
            />
        </div>
    </section>
}

export default Steps