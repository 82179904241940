import React from 'react';
import styles from './Button.module.scss';
import Loader from '../Loader';

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    c?: number | string,
    loading?: boolean
}

const Button: React.FC<IProps> = props => {
    const { c, loading, ...rest } = props

    const style = {
        backgroundColor: typeof c === 'string' ? c : (!c || c === 1 ? '#05C3DD' : c === 2 ? '#2BBF00' : '#0800FF'),
        ...props.style
    }

    return (
        <button
            {...rest}
            className={`${styles.Button} ${props.disabled ? styles.disabled : ''}`}
            style={style}
            disabled={props.disabled || loading}
        >
            <div className={styles.InnerContainer}>
                {!loading ? props.children : <Loader />}
            </div>
        </button>
    )
}

export default Button;