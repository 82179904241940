import React, { useEffect, useState } from "react"
import Modal, { IProps as IModalProps } from "../../../../components/Modal"
import { IProfile } from "../../../../types"
import TextInput from "../../../../components/TextInput"
import FileInput from "../../../../components/FileInput"
import Button from "../../../../components/Button"
import Banner from "../../../../components/Banner"
import { ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../../../../firebase";

import styles from "./ModalEditProfile.module.scss"
import useAuth from "../../../../hooks/useAuth"

interface IProps extends IModalProps {
    profile: IProfile
    newProfile: boolean
    onSave: (profile: IProfile) => Promise<void>
}

const ModalEditProfile: React.FC<IProps> = props => {
    const { profile, newProfile, ...rest } = props

    const { user } = useAuth()

    const [file, setFile] = useState<File>()

    const [displayName, setDisplayName] = useState(profile?.displayName)
    const inputDisplayNameRef = React.useRef<HTMLTextAreaElement>(null)

    const [phoneNumber, setPhoneNumber] = useState<string>()

    const [aboutText, setAboutText] = useState(profile?.aboutText)

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (profile) {
            setDisplayName(profile.displayName || "")
            setAboutText(profile.aboutText || "")
            setPhoneNumber(profile.phoneNumber || "")
        }
    }, [profile])

    const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const temp = e.target.files

        if (temp) {
            setFile(temp[0])
        }
    }

    const onSave = async () => {
        if (!displayName) {
            alert("Please enter a name")
            return
        }

        setLoading(true)

        const updates: any = {}

        if (file) {
            const filePath = `users/${user!.uid}/${file.name}`
            const ref = storageRef(storage, filePath)

            const downloadURL = await uploadBytes(ref, file).then(snapshot => {
                return getDownloadURL(snapshot.ref)
            })

            updates.photoURL = downloadURL
        }

        if (displayName !== profile.displayName) {
            updates.displayName = displayName
        }

        if (aboutText !== profile.aboutText) {
            updates.aboutText = aboutText
        }

        if (phoneNumber !== profile.phoneNumber){
            updates.phoneNumber = phoneNumber
        }

        await props.onSave({ ...profile, ...updates })

        setLoading(false)
        setFile(undefined)
    }

    return <Modal
        {...rest}
        style={{
            paddingBottom: 20,
            height: "50%"
        }}
    >
        <div
            className='col a'
            style={{
                flex: 1
            }}
        >
            {profile?.photoURL &&
                <div
                    className={styles.Img}
                >
                    <img src={profile?.photoURL} />
                </div>}
            <FileInput
                style={{
                    width: '90%',
                    marginBottom: 10
                }}
                file={file}
                loading={loading}
                onChange={onFileChange}
            >
                Upload Photo
            </FileInput>
            <Banner
                style={{
                    zIndex: 2,
                    cursor: 'pointer',
                    position: 'relative',
                    marginBottom: 10
                }}
            >
                <textarea
                    ref={inputDisplayNameRef}
                    className={styles.InputDisplayName}
                    style={{
                        resize: "none"
                    }}
                    value={displayName?.toUpperCase()}
                    disabled={loading}
                    maxLength={40}
                    placeholder="Enter Name..."
                    onChange={(e) => {
                        setDisplayName(e.target.value)

                        inputDisplayNameRef.current!.style.height = '0px'

                        const scrollHeight = inputDisplayNameRef.current?.scrollHeight;

                        if (!scrollHeight) {
                            return
                        }

                        inputDisplayNameRef.current.style.height = scrollHeight + 'px'
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault()
                        }
                    }}
                    onBlur={() => {
                        if (!displayName) {
                            setDisplayName(profile?.displayName)
                        }
                    }}
                />
            </Banner>

            <TextInput
                placeholder="About Text (optional)"
                containerStyle={{
                    marginBottom: 10,
                }}
                maxLength={1024}
                value={aboutText}
                disabled={loading}
                onChange={(e) => {
                    setAboutText(e.target.value)
                }}
            />

            <div
                style={{
                    width: "80%",
                    fontSize: 14
                }}
            >
                We will send a message every time a tip is sent to this profile.<br />
                Enter the phone number in the format <span style={{fontWeight: 'bold'}}>+35799123123</span>
            </div>
            <TextInput
                placeholder="Phone Number"
                containerStyle={{
                    marginBottom: 10,
                }}
                value={phoneNumber}
                disabled={loading}
                onChange={(e) => {
                    setPhoneNumber(e.target.value)
                }}
            />

            <Button
                style={{
                    width: '90%',
                }}
                loading={loading}
                disabled={!displayName}
                onClick={onSave}
            >
                {newProfile ? "CREATE PROFILE" : "SAVE"}
            </Button>
        </div>
    </Modal>
}

export default ModalEditProfile;
