import styles from './Badges.module.scss';

const applePay = "https://firebasestorage.googleapis.com/v0/b/giftyrates.appspot.com/o/Apple_Pay_logo.svg?alt=media&token=a89e7a82-58eb-41d6-943d-7953aeba6ebb&_gl=1*vbxe2e*_ga*MTQyNzY2MTQzNy4xNjk0NjEzMTY5*_ga_CW55HF8NVT*MTY5NjQxODQwOS4xMy4xLjE2OTY0MTg0NTMuMTYuMC4w";
const googlePay = "https://firebasestorage.googleapis.com/v0/b/giftyrates.appspot.com/o/Google_Pay_Logo.svg?alt=media&token=1c143afa-c2ea-4f3f-9e86-d3e208921ff4&_gl=1*1t2jls*_ga*MTQyNzY2MTQzNy4xNjk0NjEzMTY5*_ga_CW55HF8NVT*MTY5NjQxODQwOS4xMy4xLjE2OTY0MTg1MzQuNjAuMC4w";

const Badges: React.FC = () => {

    return <section className={styles.Container}>
        <img src={applePay} />
        <img src={googlePay} />
    </section>
}

export default Badges;
