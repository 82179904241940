export const recommendedTips = [
    {
        icon: "🍕",
        amount: 2,
    },
    {
        icon: "🌭",
        amount: 2
    },
    {
        icon: "🍦",
        amount: 2,
    },
    {
        icon: "😅",
        amount: 2,
    },
    {
        icon: "🙂",
        amount: 2.5,
    },
    {
        icon: "🙃",
        amount: 2.5,
    },

    {
        icon: "⚽️",
        amount: 2.5,
    },
    {
        icon: "🌹",
        amount: 2.5
    },
    {
        icon: "🤗",
        amount: 3,
    },
    {
        icon: "🎁",
        amount: 3,
    },
    {
        icon: "😉",
        amount: 4,
    },
    {
        icon: "😎",
        amount: 5,
    },
    {
        icon: "🤪",
        amount: 5.01,
    },
    {
        icon: "🥰",
        amount: 5.5,
    },
    {
        icon: "🐶",
        amount: 4,
    },
    {
        icon: "🐱",
        amount: 4,
    },
    {
        icon: "🍻",
        amount: 4,
    },
    {
        icon: "🥂",
        amount: 5,
    },
    {
        icon: "🥇",
        amount: 5,
    },
    {
        icon: "😘",
        amount: 6
    },
    {
        icon: "🥹",
        amount: 6.5
    },
    {
        icon: "🫠",
        amount: 10,
    },
    {
        icon: "🥳",
        amount: 10
    },
    {
        icon: "🦦",
        amount: 10,
    },
    {
        icon: "😏",
        amount: 12.5
    },
    {
        icon: "👑",
        amount: 15,
    },
    {
        icon: "💎",
        amount: 20,
    },
    {
        icon: "💍",
        amount: 25,
    },
    {
        icon: "🪐",
        amount: 30,
    },
    {
        icon: "😈",
        amount: 69,
    },
    {
        icon: "💰",
        amount: 100,
    },
    {
        icon: "🌎",
        amount: 500,
    }
]
