import React from 'react';

import styles from './ALink.module.scss';

const ALink: React.FC<React.HTMLProps<HTMLAnchorElement>> = props => {

    return <a {...props} className={styles.Container}>
        {props.children}
    </a>
}

export default ALink;