import React from 'react';
import { updateDoc, auth } from '../../firebase';

import TextInput from "../TextInput";
import Button from "../Button";

interface IProps {
    onSubmit: () => void
}

const BankDetailsInput: React.FC<IProps> = (props) => {

    const [loading, setLoading] = React.useState(false)

    const [fullName, setFullName] = React.useState("")
    const [fullNameError, setFullNameError] = React.useState("")

    const [bankName, setBankName] = React.useState("")
    const [bankNameError, setBankNameError] = React.useState("")

    const [iban, setIban] = React.useState("")
    const [ibanError, setIbanError] = React.useState("")

    const [bic, setBic] = React.useState("")
    const [bicError, setBicError] = React.useState("")


    const checkFullName = () => {
        setFullNameError("")
        if (!fullName) {
            setFullNameError("Field is empty")
            return false
        }

        return true
    }

    const checkBankName = () => {
        setBankNameError("")
        if (!bankName) {
            setBankNameError("Field is empty")
            return false
        }

        return true
    }

    const checkIban = () => {
        setIbanError("")
        if (!iban) {
            setIbanError("Field is empty")
            return false
        }

        return true
    }

    const checkBic = () => {
        setBicError("")
        if (!bic) {
            setBicError("Field is empty")
            return false
        }

        return true
    }

    const onSubmit = async () => {
        setLoading(true)

        let check = checkFullName() 
        check = checkBankName() && check
        check = checkIban() && check
        check = checkBic() && check

        if (check) {
            const uid = auth.currentUser?.uid

            if (!uid) {
                throw new Error("not authenticated")
            }

            await updateDoc("users", uid, {
                bankDetails: {
                    fullName: fullName,
                    bankName: bankName,
                    iban: iban,
                    bic: bic
                }
            })

            props.onSubmit()
        } else {
            setLoading(false)
        }
    }

    return <div className='col a' style={{ width: '90%', textAlign: 'center' }}>
        <h2 style={{ maxWidth: 400, fontWeight: 'bold' }}>
            Bank Account
        </h2>

        <p>The details of the bank account we'll pay your tips to.</p>

        <TextInput
            containerStyle={{
                marginBottom: 20
            }}
            placeholder='Your Full Name'
            fullWidth
            onChange={(e) => {
                setFullName(e.target.value)
            }}
            onBlur={checkFullName}
            error={fullNameError}
        />

        <TextInput
            containerStyle={{
                marginBottom: 20
            }}
            placeholder='Your Bank Name'
            fullWidth
            onChange={(e) => {
                setBankName(e.target.value)
            }}
            onBlur={checkBankName}
            error={bankNameError}
        />

        <TextInput
            containerStyle={{
                marginBottom: 20
            }}
            placeholder='Your IBAN'
            fullWidth
            onChange={(e) => {
                setIban(e.target.value)
            }}
            onBlur={checkIban}
            error={ibanError}
        />


        <TextInput
            containerStyle={{
                marginBottom: 20
            }}
            placeholder="Your bank's BIC"
            fullWidth
            onChange={(e) => {
                setBic(e.target.value)
            }}
            onBlur={checkBic}
            error={bicError}
        />

        <p style={{ fontSize: '0.8em' }}>Note: You are still able to accept tips before you input your bank details</p>

        <Button
            loading={loading}
            onClick={onSubmit}
        >
            UPDATE
        </Button>
    </div>
}

export default BankDetailsInput
