import React from "react";

import { Bell } from "react-feather";

import styles from './Notification.module.scss';

const Notification: React.FC<React.HTMLAttributes<HTMLDivElement>> = props => {
    const { ...rest } = props;

    return <div
        className={styles.Container}
        {...rest}
    >
        <Bell size={24} />
        <div className={styles.Text}>
            {props.children}
        </div>
    </div>
}

export default Notification;