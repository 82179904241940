import React from 'react'
import { useNavigate } from 'react-router-dom'

import Button from '../../Button'

import { ArrowDown } from 'react-feather'

import Graphic from '../../../assets/img/svg/graphic.svg'

import styles from './Hero.module.scss'

const Hero: React.FC = (props) => {
    const navigate = useNavigate()

    return <>
        <img src={Graphic} className={styles.Graphic} />

        <div className={styles.Text}>
            <div className={styles.content}>
                <h1>Gifting made easy</h1>

                <p>Helping waiters earn more 💸</p>

                <Button
                    style={{ 
                        width: 'auto', 
                        marginTop: 20,
                        marginBottom: 20
                    }}
                    onClick={() => {
                        window.location.href = "https://giftyrates.com/partners.html"
                    }}
                >
                    CHECK OUT OUR API
                </Button>
            </div>

            <div className={styles.Arrow}>
                <ArrowDown />
            </div>
        </div>
    </>
}

export default Hero;