import React from 'react';

const containerStyle:React.CSSProperties = {
    color: 'gray',
    fontSize: 14,
    margin: '10px 0'
}

const lineStyle: React.CSSProperties = {
    flex: 1,
    borderTop: '1px solid',
    borderColor: 'inherit',
    margin: '0 5%'
}

const Or: React.FC<React.HTMLAttributes<HTMLDivElement>> = props => {

    return <div
        style={containerStyle}
        className='row a'
        {...props}
    >
        <div style={lineStyle} />
        OR
        <div style={lineStyle} />
    </div>
}

export default Or