import React from 'react';
import styles from './IconButton.module.scss';

const IconButton: React.FC<React.HTMLAttributes<HTMLButtonElement> & { disabled?: boolean }> = props => {
    const { disabled, ...rest } = props
    return <button
        className={`${styles.Button} ${disabled ? styles.disabled : ''}`}
        disabled={disabled}
        {...rest}
    >
        {props.children}
    </button>
}

export default IconButton;