import React, { useState } from 'react';
import { setDoc } from '../../../firebase';
import useAuth from '../../../hooks/useAuth';

import TextInput from '../../../components/TextInput';
import UsernameInput from '../../../components/UsernameInput';
import Button from '../../../components/Button';

import styles from './CreateAccount.module.scss';

import { IClientFull } from '../../../types/';
import { useNavigate } from 'react-router-dom';

interface IProps {
    setClient: (client: IClientFull) => void
}

const CreateAccount: React.FC<IProps> = props => {
    const navigate = useNavigate()

    const { user } = useAuth()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")

    const [usernameValid, setUsernameValid] = useState(false)
    const [username, setUsername] = useState("")

    const [displayName, setDisplayName] = useState("")
    const [displayNameError, setDisplayNameError] = useState("")

    const [currency, setCurrency] = useState("€")

    const checkDisplayName = () => {
        setDisplayNameError("")
        if (!displayName) {
            setDisplayNameError("Field is empty")
            return false
        }

        return true
    }

    const onSubmit = async () => {
        if (!user) {
            alert("Not logged in")
            return;
        }

        let check = usernameValid
        check = check && checkDisplayName()

        if (!check) {
            return;
        }

        try {
            setLoading(true)

            const clientData: IClientFull = {
                username: username,
                displayName: displayName,
                currency: currency,
                photoURL: "",
                // aboutText: aboutText,
                // photoURL: photoURL,
                // recommendedTips: [2, 5, 10],
                clientUid: user.uid,
            }

            await setDoc("clients", user.uid, clientData)

            props.setClient(clientData)

            navigate("/account/edit")
        } catch (err) {
            const error = err as Error
            setError(error.message)
        }

        setLoading(false)
    }

    return <div className={styles.Container}>
        <h3 style={{ textAlign: 'center' }}>Welcome!<br />Let's create your profile</h3>

        <div>
            <div>
                <p style={{ fontWeight: 'bold' }}>Username</p>
                <p>Choose a username for your unique link
                    (https://giftyrates.com/<span style={{ fontWeight: 'bold' }}>username</span>)
                </p>
            </div>

            <UsernameInput
                setUsername={(valid, value) => {
                    setUsernameValid(valid)
                    if (valid) {
                        setUsername(value)
                    }
                }}
            />

            <div>
                <p style={{ fontWeight: 'bold' }}>Display Name</p>
                <p>The name of your business</p>
            </div>

            <TextInput
                value={displayName}
                disabled={loading}
                placeholder="Display Name"
                autoComplete="name"
                onChange={(e) => setDisplayName(e.target.value)}
                onBlur={checkDisplayName}
                error={displayNameError}
            />

            {/* <div>
                <p style={{ fontWeight: 'bold' }}>About</p>
                <p>Write a sentence or two about yourself that will encourage people to tip you (optional)</p>
            </div>

            <TextInput
                value={aboutText}
                disabled={loading}
                placeholder="About"
                autoComplete="name"
                onChange={(e) => setAboutText(e.target.value)}
                onBlur={checkAboutText}
                error={aboutTextError}
            />

            <div>
                <p style={{ fontWeight: 'bold' }}>Photo</p>
                <p>Add a cute photo (optional)</p>
            </div>

            <FileInput
                file={file}
                loading={loading}
                onChange={onFileChange}
            /> */}

            <div>
                <p style={{ fontWeight: 'bold' }}>Currency</p>
                <p>Select the currency you want to accept tips in</p>
            </div>

            <select
                className={styles.Select}
                name="currency"
                id="currency"
                onChange={(e) => {
                    setCurrency(e.target.value)
                }}
            >
                <option value="€">EURO (€)</option>
                <option value="$">US DOLLAR ($)</option>
                <option value="£">GBP (£)</option>
            </select>

            {error && <div className={styles.ErrorText}>{error}</div>}

            <Button
                style={{ marginTop: 24 }}
                loading={loading}
                onClick={onSubmit}
            >
                Create
            </Button>
        </div>

    </div>
}

export default CreateAccount;
