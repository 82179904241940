import React from "react";

import { useSearchParams } from "react-router-dom";

import PaymentSuccessImg from '../../../assets/img/svg/payment-success.svg';

import styles from "./PaymentSuccess.module.scss";

const PaymentSuccessScreen: React.FC = props => {
    const [searchParams] = useSearchParams()

    const username = searchParams.get("username")

    return <div className={styles.Container}>
        <img
            src={PaymentSuccessImg}
            className={styles.Graphic}
        />
        <h1 style={{ marginTop: -35, fontWeight: 'bold' }}>Yeaa !</h1>
        <p style={{ width: '80%' }}>
            Payment successful! <br />
            Thank you for tipping and joining our mission of innovation
            through contactless tipping :)
        </p>

        {username && <>
            <h3 style={{ fontWeight: 'bold' }}>What's next?</h3>

            <p style={{ width: '80%', marginTop: 0 }}>
                The waiter will be notified, and their funds are on their way to their bank account.
            </p>
        </>}
    </div>
}

export default PaymentSuccessScreen;