import React, { InputHTMLAttributes } from 'react';

import styles from './TextInput.module.scss';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
    containerStyle?: React.CSSProperties
    left?: React.FC
    right?: React.FC
    fullWidth?: boolean
    name?: string
    error?: string
}

const TextInput: React.FC<IProps> = props => {
    const {
        containerStyle: propsContainerStyle,
        fullWidth,
        error,
        left,
        right,
        ...rest
    } = props

    const containerStyle = {
        ...propsContainerStyle,
        width: fullWidth ? '90%' : undefined
    }

    return <div
        className={`${styles.Container} ${error ? styles.error : ''}`}
        style={containerStyle}
    >
        {props.left && <props.left />}
        <input
            {...rest}
            className={styles.Input}
        />
        {props.right && <props.right />}

        <span
            className={`${styles.ErrorSpan} ${error ? styles.error : ''}`}
        >
            {error}
        </span>
    </div>
}

export default TextInput;