import React, { useEffect, useState } from 'react';
import useStripeSubmit from '../../../hooks/useStripeSubmit';
import { CardElement, useStripe, PaymentRequestButtonElement } from '@stripe/react-stripe-js';

import Button from '../../../components/Button';
import TextInput from '../../../components/TextInput';
import Loader from '../../../components/Loader';
import IconButton from '../../../components/IconButton';
import Or from '../../../components/Or';

import { ArrowLeft } from 'react-feather'

import styles from './Payment.module.scss';

const PaymentContainer: React.FC<{
    hidden?: boolean
    username: string
    amount: number
    currency: string
    goBack: () => void
    intentID: string
    setIntentID: (id: string) => void
    anonymous: boolean
    metadata: Record<string, any>
}> = props => {
    const { hidden, amount, currency, intentID, anonymous, metadata } = props;

    const stripe = useStripe()
    const {
        loading: loadingStripe,
        errorMessage,
        currencyCode,
        submitNewCard,
        submitPaymentRequest
    } = useStripeSubmit(
        amount,
        currency,
        intentID,
        props.setIntentID,
        anonymous,
        metadata
    );

    const [loading, setLoading] = useState(true)
    const [paymentRequest, setPaymentRequest] = useState<any>(null);

    const [inputCardShown, setInputCardShown] = useState(false);
    const [focus, setFocus] = useState(false);
    const [inputNameValue, setInputNameValue] = useState('');
    const [inputEmailValue, setInputEmailValue] = useState('');

    useEffect(() => {
        if (stripe && !loadingStripe) {
            const pr = stripe.paymentRequest({
                country: 'CY',
                currency: currencyCode,
                total: {
                    label: 'gift for ' + props.username,
                    amount: amount * 100,
                },
                requestPayerName: true,
            });

            pr.canMakePayment().then(result => {
                if (result) {
                    setPaymentRequest(pr)
                }

                setLoading(false)
            })

            pr.on('paymentmethod', async (e) => {
                submitPaymentRequest(e)
            })
        }
    }, [stripe, loadingStripe]);

    if (loading && !hidden) {
        return <Loader style={{ color: '#05C3DD', alignSelf: 'center', marginTop: 30 }} />
    }

    return (
        <div
            className={styles.Container}
            style={{
                display: hidden ? "none" : undefined
            }}
        >
            <div className={'row a'}>
                <div style={{ flex: 1 }}>
                    <IconButton onClick={props.goBack}>
                        <ArrowLeft color="gray" />
                    </IconButton>
                </div>

                <div>
                    <h3>{metadata.icon} {currency}{amount} gift</h3>
                </div>

                <div style={{ flex: 1 }}>

                </div>
            </div>

            {paymentRequest && <>
                <div style={{ marginBottom: 10 }}>
                    <PaymentRequestButtonElement options={{ paymentRequest }} />
                </div>
                <Or />
            </>}

            {!inputCardShown && paymentRequest ?
                <Button
                    style={{ fontWeight: 'normal' }}
                    onClick={() => setInputCardShown(true)}
                >
                    Input Card Details
                </Button>
                : renderNewCard()}

            {!!errorMessage && <span className={styles.ErrorSpan}>{errorMessage} </span>}

            <div className={styles.info}>We ask for your email to send you the receipt.</div>

            {anonymous && <div className={styles.info}>This gift will be anonymous. We ask for your name only to confirm the payment card.</div>}
        </div>
    )

    function renderNewCard() {
        return <form
            className={styles.CardForm}
            onSubmit={(e) => submitNewCard(e, inputNameValue, inputEmailValue)}
        >
            <div className={styles.CardBox + ' ' + (focus ? styles.active : '')}>
                <CardElement
                    onFocus={() => setFocus(true)}
                    onBlur={() => setFocus(false)}
                />
            </div>

            <TextInput
                name="name"
                placeholder="Name on the card"
                onChange={e => setInputNameValue(e.target.value)}
                style={{ fontSize: 14 }}
                containerStyle={{ marginBottom: 10 }}
            />

            <TextInput
                name="email"
                type="email"
                placeholder="Your email address"
                onChange={e => setInputEmailValue(e.target.value)}
                style={{ fontSize: 14 }}
                containerStyle={{ marginBottom: 10 }}
            />

            <div className={styles.ActionBox}>
                <Button
                    type="submit"
                    disabled={loadingStripe}
                >
                    PAY
                </Button>
            </div>
        </form>
    }
}

export default PaymentContainer;