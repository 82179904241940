import React from 'react'
import { Outlet, Navigate, useLocation } from 'react-router';
import useAuth from '../../hooks/useAuth';
import AccountLayout from '../../Layout/AccountLayout';

const PrivateOutlet: React.FC = () => {
    const { loading, user } = useAuth();
    const { pathname } = useLocation();

    if (loading) return null;

    return (user && !user.isAnonymous) ? (
        <AccountLayout>
            <Outlet />
        </AccountLayout>
    ) : <Navigate to="/login" state={{ from: pathname }} />;
}

export default PrivateOutlet;