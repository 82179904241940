import { useState, useRef } from "react";

import { api } from "../../../firebase";

import SelectStars from "../../../components/SelectStars";
import TextInput from "../../../components/TextInput";
import Button from "../../../components/Button";

import { Check } from "react-feather";
import { IProfile } from "../../../types";

interface IProps {
    username: string
    displayName: string
    profile: IProfile
}

const ReviewForm: React.FC<IProps> = props => {
    const [stars, setStars] = useState(0)

    const [submitted, setSubmitted] = useState(false)

    const [loading, setLoading] = useState(false)

    const comment = useRef("")

    if (submitted) {
        return <>
            <div className="row a" style={{
                justifyContent: "center",
                marginTop: 20,
                marginBottom: 25,
            }}>
                <Check
                    color="#4BB543"
                    size={50}

                />
            </div>
            <h3 style={{ textAlign: "center" }}>Thanks for the feedback!</h3>
        </>
    }

    const onSubmit = async () => {
        setLoading(true)

        const addReviewFunc = api("user-client-reviews-add")

        try {
            await addReviewFunc({
                username: props.username,
                displayName: props.displayName,
                stars: stars,
                comment: comment.current
            })

            setSubmitted(true)
        } catch (error) {
            const err = error as Error
            alert("Sorry, an unexpected error has occured. Details: " + err.message)
        }

        setLoading(false)
    }

    return <>
        <h3 style={{ textAlign: "center" }}>Leave a review</h3>

        <SelectStars value={stars} setStars={setStars} />

        <TextInput
            placeholder="Comment (optional)"
            containerStyle={{
                marginTop: 15
            }}
            onChange={(e) => {
                comment.current = e.target.value
            }}
        />

        <Button
            style={{
                marginTop: 15,
                marginBottom: 20,
            }}
            onClick={onSubmit}
            disabled={!stars}
            loading={loading}
        >
            SUBMIT
        </Button>
    </>
}

export default ReviewForm;
