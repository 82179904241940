import React from 'react';
import useAuth from '../../hooks/useAuth';
import { collectionRef } from '../../firebase';
import { query, orderBy, limit, getDocs, startAfter, endBefore, limitToLast, Query } from 'firebase/firestore';
import { ISOtoCurrencySymbol } from '../../utils/common';

import IconButton from '../IconButton';

import { ArrowLeft, ArrowRight } from 'react-feather';

import styles from './TipsHistory.module.scss';

import { ITip } from '../../types';

const DOCS_PER_PAGE = 10;

const TipsHistory: React.FC = props => {
    const { user, loading: loadingUser } = useAuth()

    const [data, setData] = React.useState<ITip[] | null>(null)

    const [loadingData, setLoadingData] = React.useState(true)

    const [backDisabled, setBackDisabled] = React.useState(true)
    const [nextDisabled, setNextDisabled] = React.useState(true)

    const page = React.useRef(0)

    React.useEffect(() => {
        if (user) {
            init()
        }
    }, [user])

    const init = async () => {
        const ref = collectionRef(`users/${user!.uid}/tips`)

        const q = query(
            ref,
            orderBy("date", "desc"),
            limit(DOCS_PER_PAGE)
        )

        const tips = await getTips(q)

        if (tips.length > 0) {
            setNextDisabled(false)
        }
    }

    const nextPage = async (last: ITip) => {
        setLoadingData(true)

        const ref = collectionRef(`users/${user!.uid}/tips`)

        const q = query(
            ref,
            orderBy("date", "desc"),
            startAfter(last["date"]),
            limit(DOCS_PER_PAGE)
        )

        const tips = await getTips(q)

        setBackDisabled(false)

        if (tips.length > 0) {
            page.current++
        }

        if (tips.length < DOCS_PER_PAGE) {
            setNextDisabled(true)
        }

        if (tips.length === DOCS_PER_PAGE) {
            setNextDisabled(false)
        }
    }

    const prevPage = async (first: ITip) => {
        setLoadingData(true)

        const ref = collectionRef(`users/${user!.uid}/tips`)

        const q = query(
            ref,
            orderBy("date", "desc"),
            endBefore(first["date"]),
            limitToLast(DOCS_PER_PAGE)
        )

        const tips = await getTips(q)

        page.current--

        if (tips.length === 0 || page.current === 0) {
            setBackDisabled(true)
        }

        if (tips.length < DOCS_PER_PAGE) {
            setNextDisabled(true)
        }

        if (tips.length === DOCS_PER_PAGE) {
            setNextDisabled(false)
        }
    }

    const getTips = async (query: Query) => {
        const querySnapshot = await getDocs(query)

        const temp: any[] = []

        querySnapshot.forEach(doc => {
            temp.push(doc.data())
        })

        if (temp.length > 0) {
            setData(temp)
        }

        setLoadingData(false)

        return temp;
    }

    const loading = loadingData || loadingUser

    return <div className={styles.Container}>
        <h4 style={{ textAlign: 'center' }}>Gifts History</h4>

        <table className={styles.Table}>
            <thead>
                <tr>
                    <th>
                        Name
                    </th>
                    <th>
                        Amount
                    </th>
                    <th>
                        Date
                    </th>
                </tr>
            </thead>
            <tbody>
                {data?.map((tip) => {
                    const {
                        user,
                        amount,
                        currency,
                        anonymous,
                        date: firestoreDate
                    } = tip

                    const date = new Date(
                        firestoreDate.seconds * 1000
                    ).toLocaleString()

                    return <tr key={`${user.uid}_${date}`}>
                        <td>
                            {anonymous ? "Anonymous" : user.name}
                        </td>

                        <td>
                            {ISOtoCurrencySymbol(currency)}{amount}
                        </td>

                        <td>
                            {date}
                        </td>
                    </tr>
                })}
            </tbody>
        </table>

        <div
            className='row a'
            style={{
                width: '100%',
                justifyContent: 'space-around'
            }}
        >
            <IconButton
                disabled={backDisabled || loading}
                onClick={() => {
                    if (data) {
                        prevPage(data[0])
                    }
                }}
            >
                <ArrowLeft />
            </IconButton>

            <IconButton
                disabled={nextDisabled || loading}
                onClick={() => {
                    if (data) {
                        nextPage(data[data.length - 1])
                    }
                }}
            >
                <ArrowRight />
            </IconButton>
        </div>
    </div>
}

export default TipsHistory;
