import React from 'react';
import { useNavigate } from 'react-router-dom';

import IconButton from '../../IconButton';

import { ArrowLeft } from 'react-feather';

import Logo from '../../../assets/img/logo-full.png';

import styles from './AuthForm.module.scss';

interface IProps extends React.HTMLAttributes<HTMLFormElement> {
    onSubmit: (e: React.SyntheticEvent) => Promise<void>
    title: string
    back?: () => void
}

const AuthForm: React.FC<IProps> = (props) => {
    const navigate = useNavigate()

    const { title, back, ...rest } = props

    return (
        <form className={styles.Form} {...rest}>
            {props.back &&
                <IconButton
                    style={{
                        position: 'absolute',
                        top: 10,
                        left: 10
                    }}
                    onClick={props.back}
                >
                    <ArrowLeft />
                </IconButton>}

            <img
                className={styles.Logo}
                src={Logo}
                onClick={() => {
                    navigate('/')
                }}
            />
            <h3 style={{ padding: '30px 0', fontWeight: 'bold' }}>{props.title}</h3>
            {props.children}
        </form>
    )
}

export default AuthForm