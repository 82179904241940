import React, { useState } from "react";

import { useParams } from "react-router-dom";
import { onAuthStateChanged, signInAnonymously } from "firebase/auth";
import { auth, api } from "../../firebase";

import { Elements } from '@stripe/react-stripe-js';
import { stripePromise } from '../../stripe';

import ProfileCard from "../../components/ProfileCard";
import IconButton from "../../components/IconButton";
import Banner from "../../components/Banner";

import PaymentContainer from "./Payment";
import SelectTip from "./Select";
import ReviewForm from "./ReviewForm";

import { ArrowLeft } from "react-feather";

import styles from './Tip.module.scss';
import Loader from "../../components/Loader";

import { IProfile } from "../../types";
import { recommendedTips } from "../../constants/tips";

export interface IClient {
    username: string
    displayName: string
    photoURL: string
    profiles?: IProfile[]
    currency: string
}

const TipScreen: React.FC = props => {
    const { username } = useParams()

    const [loading, setLoading] = useState(true)

    const [client, setClient] = useState<IClient | null>(null)
    const [profileIndex, setProfileIndex] = useState<number>()
    const [icon, setIcon] = useState("")

    const [amount, setAmount] = useState<number | ''>(2)
    const [selectedTipIndex, setSelectedTipIndex] = useState(0)

    const [paymentShown, setPaymentShown] = useState(false)

    const intentID = React.useRef("")
    const anonymous = React.useRef(false)

    React.useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (!user) {
                await signInAnonymously(auth)
            } else {
                const getClient = api('user-client-get')
                const client: IClient | null = await getClient({ username })
                    .then((res: any) => { console.log(res); return res.data; })
                    .catch((err) => {
                        console.log('entered')
                        console.log(err)
                        return null
                    })

                setClient(client)

                setLoading(false)
            }
        })

        return () => {
            unsubscribe()
        }
    }, [])

    if (loading) {
        return <div className={styles.Container}>
            <Loader
                style={{
                    color: '#05C3DD',
                    width: 40,
                    height: 40,
                    alignSelf: 'center'
                }}
            />
        </div>
    }

    if (!client) {
        return null
    }

    const profile = (profileIndex !== undefined && client.profiles) ? client.profiles[profileIndex] : null;

    if (profileIndex === undefined || !profile) {
        return <div className={styles.Container}>
            <h2
                style={{
                    textAlign: "center",
                    marginTop: 10
                }}
            >
                {client.displayName}
            </h2>

            {client.profiles?.map((profile, index) =>
                <ProfileCard
                    key={"profile" + index}
                    profile={profile}
                    onClick={() => setProfileIndex(index)}
                />
            )}
        </div>
    }

    return <Elements stripe={stripePromise}>
        <div className={styles.Container}>
            <IconButton
                style={{
                    position: "absolute",
                    top: 5,
                    left: "2.5%",
                    backgroundColor: "white"
                }}
                onClick={() => setProfileIndex(undefined)}
            >
                <ArrowLeft color="gray" />
            </IconButton>

            <div className='col a'>
                {profile.photoURL && <div className={styles.Img}>
                    <img src={profile.photoURL} />
                </div>}
                <Banner>
                    {profile.displayName.toUpperCase()}
                </Banner>
            </div>

            <p style={{ width: '80%', alignSelf: 'center', textAlign: 'center' }}>
                {profile.aboutText}
            </p>

            <div className={'col ' + styles.WhiteContainer}>
                {!paymentShown ?
                    <SelectTip
                        amount={amount}
                        setAmount={setAmount}
                        selectedTipIndex={selectedTipIndex}
                        setSelectedTipIndex={setSelectedTipIndex}
                        client={client}
                        anonymous={anonymous.current}
                        setAnonymous={(bool) => anonymous.current = bool}
                        onNext={() => setPaymentShown(true)}
                    />
                    : <PaymentContainer
                        goBack={() => setPaymentShown(false)}
                        username={username!}
                        amount={amount || 0}
                        currency={client.currency}
                        intentID={intentID.current}
                        setIntentID={(id: string) => intentID.current = id}
                        anonymous={anonymous.current}
                        metadata={{
                            icon: recommendedTips[selectedTipIndex]?.icon || "",
                            profileIndex,
                        }}
                    />
                }
            </div>

            <div className={styles.disclaimer}>
                By tipping you agree to our <a href="terms">Terms Of Use</a> and <a href="privacy">Privacy Policy</a>
            </div>


            <div
                className={styles.WhiteContainer}
                style={{
                    marginTop: 20,
                    marginBottom: 100,
                }}
            >
                <ReviewForm 
                    username={client.username}
                    displayName={profile.displayName}
                    profile={profile} 
                />
            </div>
        </div>
    </Elements>
}

export default TipScreen;