import { User } from "react-feather";
import styles from "./ProfileCard.module.scss";
import { IProfile } from "../../types";

interface IProps {
    profile: IProfile
    onClick: (profile: IProfile) => void
}

const ProfileCard: React.FC<IProps> = props => {
    const { profile } = props

    return <div
        className={`row a ${styles.Container}`}
        onClick={() => props.onClick(profile)}
    >
        {profile.photoURL ?
            <div className={styles.ImgContainer}>
                <img
                    src={profile.photoURL}
                    className={styles.Img}
                />
            </div>
            :
            <div
                className={styles.ImgContainer}
                style={{
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <User color="white" size={32} />
            </div>
        }
        <div
            className="col"
            style={{
                flex: 1,
                justifyContent: "center",
                width: "100%",
                paddingLeft: 10,
                boxSizing: "border-box",
                minWidth: 0
            }}
        >
            <h3
                style={{
                    marginTop: 0,
                    marginBottom: 4
                }}
            >
                {profile.displayName?.toUpperCase()}
            </h3>
            <div className={styles.AboutText}>{profile.aboutText}</div>
        </div>
    </div>
}

export default ProfileCard;
