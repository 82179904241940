import React, { useState } from 'react';
import { useOutletContext, useNavigate } from 'react-router-dom';
import { updateDoc } from "../../../firebase";

import useAuth from '../../../hooks/useAuth';

import Button from '../../../components/Button';
import TextInput from '../../../components/TextInput';
import UsernameInput from '../../../components/UsernameInput';
import ALink from '../../../components/ALink';

import styles from './EditAccount.module.scss';

import { IClientFull, IProfile } from '../../../types';
import EditProfileCard from '../../../components/EditProfileCard';
import ModalEditProfile from './ModalEditProfile';

interface IProps {

}

const EditAccount: React.FC<IProps> = props => {
    const navigate = useNavigate()

    const [client, setClient] = useOutletContext<[
        IClientFull,
        React.Dispatch<React.SetStateAction<IClientFull>>
    ]>()

    const { user } = useAuth()

    const [loading, setLoading] = useState(false)
    const [changes, setChanges] = useState(false)

    const [editUsername, setEditUsername] = useState(false)
    const [username, setUsername] = useState(client.username)
    const [usernameValid, setUsernameValid] = useState(false)

    const [displayName, setDisplayName] = useState(client.displayName)

    const [selectedProfile, setSelectedProfile] = useState<IProfile>()
    const [selectedProfileIndex, setSelectedProfileIndex] = useState<number>()

    const [newProfile, setNewProfile] = useState(false)

    const onSave = async () => {
        let check = usernameValid || (username === client.username)

        if (!check) {
            alert("Username invalid")
            return
        }

        setLoading(true)

        const updates: any = {}

        if (username !== client.username) {
            updates.username = username
        }

        if (displayName !== client.displayName) {
            updates.displayName = displayName
        }

        await updateDoc(`clients`, user!.uid, updates)

        setLoading(false)
        setChanges(false)
        setEditUsername(false)
        setClient({ ...client, ...updates })
    }

    return <>
        <div className={styles.Container}>
            <ALink
                style={{
                    alignSelf: 'center'
                }}
                onClick={() => {
                    navigate("../")
                }}
            >
                BACK
            </ALink>

            {!editUsername ?
                <ALink
                    style={{
                        alignSelf: 'center',
                        marginBottom: 10
                    }}
                    onClick={() => setEditUsername(true)}
                >
                    Edit Username
                </ALink> :
                <>
                    <div style={{ marginTop: 10 }}>Username</div>
                    <div
                        className='row'
                    >
                        <UsernameInput
                            username={client.username}
                            setUsername={(valid, value) => {
                                setUsernameValid(valid)
                                if (valid) {
                                    setChanges(true)
                                    setUsername(value)
                                }
                            }}
                            disabled={loading}
                        />
                        {/* <IconButton
                            onClick={() => {
                                setEditUsername(false)
                                setUsername(client.username)
                            }}
                        >
                            <X />
                        </IconButton> */}
                    </div>
                </>
            }

            <div>Display Name</div>
            <TextInput
                placeholder="Display Name"
                containerStyle={{
                    marginBottom: 10
                }}
                defaultValue={client.displayName}
                onChange={(e) => {
                    setChanges(true)
                    setDisplayName(e.target.value)
                }}
            />

            {changes &&
                <Button
                    loading={loading}
                    onClick={onSave}
                >
                    SAVE
                </Button>
            }

            <div>Profiles</div>

            {client.profiles?.length ?
                client.profiles?.map((profile, index) =>
                    <EditProfileCard
                        key={"edit_profile_card" + index}
                        profile={profile}
                        onClick={(profile) => {
                            setNewProfile(false)
                            setSelectedProfile(profile)
                            setSelectedProfileIndex(index)
                        }}
                        onDelete={async () => {
                            const temp = [...client.profiles!]

                            temp.splice(index, 1)

                            setClient({ ...client, profiles: temp })

                            await updateDoc("clients", user!.uid, { profiles: temp })
                        }}
                    />
                ) : <h5>No profiles created yet</h5>}

            <Button
                style={{
                    width: "95%",
                    alignSelf: "center"
                }}
                onClick={() => {
                    setNewProfile(true)
                    setSelectedProfile({
                        displayName: "",
                    })
                    setSelectedProfileIndex(client.profiles?.length || 0)
                }}
            >
                + Create Profile
            </Button>
        </div>

        <ModalEditProfile
            profile={selectedProfile!}
            newProfile={newProfile}
            active={selectedProfile ? true : false}
            setActive={(val) => setSelectedProfile(val ? val : undefined)}
            onSave={async (profile) => {
                const profiles = client.profiles || []

                profiles[selectedProfileIndex!] = profile

                await updateDoc("clients", client.clientUid, { profiles })

                setClient({ ...client, profiles })
                setSelectedProfile(undefined)
            }}
        />
    </>
}

export default EditAccount