import React from "react";

import { CheckCircle } from "react-feather";

import styles from "./ForWho.module.scss";

const workers = ["RESTAURANTS", "BARS", "CAFES", "SINGERS", "TAXI DRIVERS", "STREET PERFORMERS", "SALONS", "MUSICIANS", "STREAMERS", "WAITERS", "MAGICIANS"]

const ForWho: React.FC = (props) => {

    return <section className={styles.Container}>
        <h2 style={{ fontWeight: 'bold' }}>Who is it for?</h2>

        <div className={styles.Workers}>
            {workers.map(worker => {
                return <div className={styles.Worker}>
                    <CheckCircle
                        color="#4BB543"
                        strokeWidth={3}
                        style={{
                            marginBottom: 5
                        }}

                    />
                    {worker}
                </div>
            })}
        </div>
    </section>
}

export default ForWho;