import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import useAuth from "../../../hooks/useAuth";

import { auth } from "../../../firebase";
import { signInWithEmailAndPassword } from "@firebase/auth";

import AuthForm from "../../../components/Forms/Auth";
import Button from "../../../components/Button";
import TextInput from "../../../components/TextInput";
import ALink from "../../../components/ALink";

import styles from './Login.module.scss';

const SignIn: React.FC = props => {
    const navigate = useNavigate();
    const { user } = useAuth()

    React.useEffect(() => {
        if (user && !user.isAnonymous) {
            navigate("/account")
        }
    }, [user])

    const [email, setEmail] = React.useState('');
    const [emailError, setEmailError] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [errorPassword, setPasswordError] = React.useState('');
    const [errorFirebase, setFirebaseError] = React.useState('');
    const [loading, setLoading] = useState(false);

    function checkEmail() {
        setEmailError('')
        let regEmail = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i).test(email)
        if (!regEmail) {
            setEmailError('Invalid email address');
            return false
        }
        return true
    }

    function checkPassword() {
        setPasswordError('')
        if (password === '') {
            setPasswordError('Field is empty')
            return false;
        }
        return true
    }
    async function handleSubmitIn(e: React.SyntheticEvent) {
        e.preventDefault()
        try {
            setLoading(true);
            setFirebaseError("");

            let check = checkEmail();
            check = checkPassword() && check;
            if (check) {
                await signInWithEmailAndPassword(
                    auth,
                    email,
                    password,
                );
                navigate('/account');
            }
        } catch (error: any) {
            if (error.code === "auth/user-not-found") {
                setFirebaseError("User not found. Please try again with a different email address")
            } else if (error.code === "auth/user-disabled") {
                setFirebaseError("Could not log you in. Please contact support for more help")
            } else if (error.code === "auth/wrong-password") {
                setFirebaseError("Wrong password")
            } else {
                setFirebaseError(error.message)
            }
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className={styles.Container}>
            <AuthForm onSubmit={handleSubmitIn} title='Login to your account'>
                <TextInput
                    name="email"
                    placeholder="Email"
                    type="email"
                    containerStyle={{ marginBottom: 24 }}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onBlur={checkEmail}
                    error={emailError}
                />

                <TextInput
                    name="password"
                    placeholder="Password"
                    type="password"
                    autoComplete="current-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onBlur={checkPassword}
                    error={errorPassword}
                />

                <div style={{ display: 'flex', marginTop: 10, marginBottom: 20 }}>
                    <ALink style={{ marginLeft: 'auto' }} onClick={() => navigate("/forgot")}>
                        Forgot Password?
                    </ALink>
                </div>

                <div>
                    <Button type="submit" disabled={loading}>
                        Sign In
                    </Button>
                </div>

                <div style={{ display: 'flex', alignItems: 'center', marginTop: 35 }}>
                    <span>Don't have an account yet? <ALink
                        onClick={() => navigate("/register")}
                    >
                        Create one here
                    </ALink></span>


                </div>
                {errorFirebase !== "" && <p className={styles.errorText}>{errorFirebase}</p>}
            </AuthForm>
        </div>
    );
};

export default SignIn;