import React, { useState } from "react";
import styles from './Forgot.module.scss';

import AuthForm from "../../../components/Forms/Auth";
import Button from "../../../components/Button";
import TextInput from "../../../components/TextInput";
import { useNavigate } from 'react-router-dom';

import { auth } from "../../../firebase";
import { sendPasswordResetEmail } from "@firebase/auth";
import ALink from "../../../components/ALink";

const ForgotPasswordScreen: React.FC = props => {
    const navigate = useNavigate();
    const [email, setEmail] = React.useState('');
    const [emailError, setEmailError] = React.useState('');
    const [errorFirebase, setFirebaseError] = React.useState('');
    const [loading, setLoading] = useState(false);
    const [emailSent, setEmailSent] = useState(false);

    function checkEmail() {
        setEmailError('')
        let regEmail = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i).test(email)
        if (!regEmail) {
            setEmailError('Invalid email address');
            return false
        }
        return true
    }

    async function handleSubmitIn(e: React.SyntheticEvent) {
        e.preventDefault()
        try {
            setLoading(true);
            setFirebaseError("");

            let check = checkEmail();
            if (check) {
                await sendPasswordResetEmail(
                    auth,
                    email,
                );
                setEmailSent(true)
            }
        } catch (error: any) {
            if (error.code === "auth/user-not-found") {
                setFirebaseError("User not found. Please try again with a different email address")
            } else if (error.code === "auth/user-disabled") {
                setFirebaseError("Could not log you in. Please contact support for more help")
            } else {
                setFirebaseError(error.message)
            }
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className={styles.Container}>
            <AuthForm onSubmit={handleSubmitIn} title='Forgot password' back={() => navigate("/login")}>
                {!emailSent ? <><TextInput
                    name="email"
                    placeholder="Email"
                    type="email"
                    containerStyle={{ marginBottom: 24 }}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onBlur={checkEmail}
                    error={emailError}
                />

                    <div>
                        <Button type="submit" disabled={loading}>
                            SEND
                        </Button>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', marginTop: 35 }}>
                        <span>Don't have an account yet? <ALink
                            onClick={() => navigate("/register")}
                        >
                            Create one here
                        </ALink></span>
                    </div>
                    {errorFirebase !== "" && <p className={styles.errorText}>{errorFirebase}</p>}
                </> : <>
                    <p>Password reset email sent!</p>
                    <p>Please check your inbox and spam</p>
                </>}
            </AuthForm>
        </div>
    );
};

export default ForgotPasswordScreen;