import React from 'react'
import styles from "./Modal.module.scss";

import IconButton from '../IconButton'
import { X } from 'react-feather'

export interface IProps {
    children?: JSX.Element | JSX.Element[]
    style?: React.CSSProperties
    active: boolean
    setActive: (val: any) => void
    disableCloseOnBackClick?: boolean
    x?: boolean
}

const Modal = ({
    children,
    style,
    active,
    setActive,
    disableCloseOnBackClick,
    x
}: IProps) => {
    const innerContainerStyle: React.CSSProperties = {
        ...style
    }

    return (
        <div className={`${styles.BackContainer} ${active ? styles.active : ''}`}
            onClick={() => !disableCloseOnBackClick && setActive(false)}>
            <div
                className={`${styles.Modal} ${active ? styles.active : ''}`}
                style={innerContainerStyle}
                onClick={e => e.stopPropagation()}
            >
                {x && <IconButton
                    style={{
                        position: 'absolute',
                        top: 4,
                        right: 6,
                        color: "gray"
                    }}
                    onClick={() => setActive(false)}
                >
                    <X />
                </IconButton>}
                {children}
            </div>
        </div>
    )
}

export default Modal;