import styles from './About.module.scss'

import { aboutTextP1, aboutTextP2 } from '../../../constants'

const About: React.FC = () => {

    return <section className={styles.Container}>
        <h2 style={{ fontWeight: 'bold' }}>About Us</h2>
        <p>{aboutTextP1}</p>

        <p>{aboutTextP2}</p>
    </section>
}

export default About