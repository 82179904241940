import React from 'react'

import { Heart } from 'react-feather'

import styles from './Footer.module.scss'

import LogoFull from '../../../assets/img/logo-full.png'

const Footer: React.FC = (props) => {

    return <footer className={styles.footer}>
        <div className={styles.SiteInfo}>
            <div className={styles.Info}>
                <img
                    className={styles.Logo}
                    src={LogoFull}
                />
            </div>

            <div className={styles.About}>

            </div>

            <div className={styles.Col}>
                <h3 style={{ marginTop: 0 }}>Terms</h3>

                <a href="https://giftyrates.com/privacy/">
                    Privacy Policy
                </a>
                <a href="https://giftyrates.com/terms/">
                    Terms Of Use
                </a>
            </div>

            <div className={styles.Col}>
                <h3 style={{ marginTop: 0 }}>Contact</h3>

                <a href="tel://+35799277580">
                    +357 99277580
                </a>
            </div>
        </div>

        GiftyRates © {new Date().getFullYear()}
        {/* <a href='https://tolypash.com' target='_blank' rel='noreferrer'>
            <Heart color="red" size={14} style={{ marginLeft: 10 }} />
        </a> */}
    </footer>
}

export default Footer;
