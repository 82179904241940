import React from 'react';

import Button from "../Button";
import { UploadCloud } from "react-feather";

import styles from "./FileInput.module.scss";

interface IProps extends React.HTMLAttributes<HTMLButtonElement> {
    file?: File
    loading?: boolean
    onChange?: (e: any) => void
}

const FileInput: React.FC<IProps> = props => {
    const { file, loading, onChange, style, ...rest } = props;
    const fileRef = React.useRef<HTMLInputElement | null>(null)

    return <>
        <Button
            style={{
                background: 'none',
                border: '2px solid #A5A5A5',
                color: '#A5A5A5',
                ...style
            }}
            disabled={loading}
            onClick={() => {
                fileRef.current?.click()
            }}
            {...rest}
        >
            <UploadCloud style={{ marginRight: 10 }} />
            {props.children || "Upload"}
            {file && <span className='muted' style={{ marginLeft: 10 }}>{file.name}</span>}

            <input
                accept="image/*"
                style={{ display: 'none' }}
                ref={fileRef}
                id='selectPhoto'
                type="file"
                onChange={onChange}
            />
        </Button>
    </>
}

export default FileInput